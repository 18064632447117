





























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import localeNl  from './nl.json';
import Mustache from "mustache";
import {Action} from 'vuex-class';

@Component({
  components: {}
})
export default class TemplateConstructor extends Vue {
  @Prop(Object) settings;
  @Prop(Object) mergeTags;
  @Prop(Object) designTags;
  @Prop(Object) template;
  @Prop(Object) inputJson;
  @Action('uploadFileToStorage') uploadFileToStorage;

  editor: any;
  exportedHtml = null;
  showModalExportHtml = false;
  showRenameDialog = false;
  renameTemplateName = '';

  mounted() {
    this.loadEditor();
    this.loadDesign();
  }

  showRenameTemplate() {
    this.showRenameDialog = true;
    this.renameTemplateName = this.template.name;
  }

  onRenameTemplate() {
    this.showRenameDialog = false;
    this.$emit('onRenameTemplate', this.renameTemplateName);
    this.renameTemplateName = '';
  }

  loadEditor() {
    /* eslint-disable */
    // @ts-ignore: Unreachable code error
    this.editor = unlayer.createEditor({
      id: 'editor-container',
      displayMode: this.settings.displayMode,
      appearance: this.settings.appearance,
      mergeTags: this.mergeTags,
      locale: this.settings.locale,
      translations: {
        'nl-NL': localeNl
      },
    });

    this.editor.registerCallback('image', (files, done) => {
      const file = files.attachments[0];

      file.toPublic = true;
      file.onlyRef = true;
      file.addTenant = true;
      file.storageRef = 'emailFiles/';

      file.procentShowCallBack = (snapshot) => {
        const loaded = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
        done({ progress: loaded})
      };

      this.uploadFileToStorage(file).then(upfile => {
        done({ progress: 100, url: upfile.downloadableURL })
      });
    });


  }

  openDesign() {
    this.loadDesign();
  }

  @Watch('template')
  async loadNewDesign() {
    await this.loadDesign();
  }

  async loadDesign() {
    if (this.template.design) {
      await this.editor.loadDesign(this.template.design);
    }
  }

  saveDesign() {
    this.editor.saveDesign((design) => {
          this.template.design = JSON.parse(JSON.stringify(design));
          this.$emit('saveTemplate', this.template);
        }
    )
  }

  exportHtml() {
    this.editor.exportHtml(
        (data) => {
          this.exportedHtml = Mustache.render(data.html, this.inputJson);
          this.showModalExportHtml = true;
        }
    )
  }

  autoSave(){
    if (this.template.ID) this.saveDesign();
  }
}
